import React, { useEffect } from 'react';

import { useToast } from 'hooks/toast';
import { ToastMessage } from 'types';
import { Container } from './styles';

interface ToastProps {
  toast: ToastMessage;
  style: object;
}

const Toast: React.FC<ToastProps> = ({ toast, style }) => {
  const { removeToast } = useToast();
  const timeOnScreen = toast.type === 'error' ? 13000 : 5000;

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(toast.id);
    }, timeOnScreen);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, toast.id, timeOnScreen]);

  return (
    <Container
      type={toast.type || 'error'}
      style={style}
    >
      <div>
        <strong>{toast.title}</strong>
      </div>
      {toast.description && (
        <div style={{ marginTop: '8px' }}>
          <strong>{toast.description}</strong>
        </div>
      )}

      <button onClick={() => removeToast(toast.id)} type="button">
        Ok
      </button>
    </Container>
  );
};

export default Toast;
