import React, { Suspense, lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Route from './Routes';
import Fallback from '../pages/FallbackPage';
const ExportSalesReport = lazy(() => import('../pages/ExportSalesReport'))
const ServiceDetails = lazy(() => import('../pages/ServiceDetails'))
const MobileErrorPage = lazy(() => import('../pages/MobileError'))
const SignIn = lazy(() => import('../pages/SignIn'));
const ProductRegistration = lazy(() => import('../pages/ProductRegistration'));
const ServicesRegister = lazy(() => import('../pages/ServicesRegister'));
const ProductDetails = lazy(() => import('../pages/ProductDetails'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Requests = lazy(() => import('../pages/Requests'));
const RequestsInfo = lazy(() => import('../pages/RequestsInfo'));
const Services = lazy(() => import('../pages/Services')) ;
const Store = lazy(() => import('../pages/Store'));
const Financial = lazy(() => import('../pages/Financial'));
const Transfer =  lazy(() => import('../pages/Transfer'));
const StoreRating = lazy(() => import('../pages/StoreRating'));
const Products = lazy(() => import('../pages/Products'));
const ForgetPassword = lazy(() => import('../pages/ForgetPassword'));
const Schedule = lazy(() => import('../pages/Schedules'));
const CardStoreTransferTed = lazy(() => import('../pages/StoreTransferTed'));
const NotificationsPage = lazy(() => import('../pages/NotificationsPage'));
const SalesReport = lazy(() => import('../pages/SalesReport'));
const Bankdetails = lazy(() => import('../pages/Bankdetails'));
const FinancialExtract = lazy(() => import('../pages/FinancialExtract'));
const ForgetToken = lazy(() => import('../pages/ForgetToken'));
const SupportPage = lazy(() => import('../pages/Sopport'));
const SaleOff = lazy(() => import('../pages/SaleOff'));
const RedirectPage = lazy(() => import('../pages/Redirect'));

const Routes: React.FC = () => (
    <Suspense fallback={<Fallback/>}>
      <Switch>
        <Route path="/login/:token?" exact component={SignIn} />
        
        <Route
          path="/cadastro-produto"
          component={ProductRegistration}
          isPrivate
        />
        <Route path="/cadastro-servico" component={ServicesRegister} isPrivate />

        <Route path="/cadastro-servico" component={ServicesRegister} isPrivate />
        <Route
          path="/produto-detalhes/:id"
          component={ProductDetails}
          isPrivate
        />
        <Route path="/servicos" component={Services} isPrivate />
        <Route path="/avaliacao" component={StoreRating} isPrivate />

        <Route
          path="/loja/transferencia-ted"
          component={CardStoreTransferTed}
          isPrivate
        />

        <Route path="/produtos" component={Products} isPrivate />

        <Route
          path="/cadastro-servico-editar/:id"
          component={ServicesRegister}
          isPrivate
        />

        <Route
          path="/servico-detalhes/:id"
          component={ServiceDetails}
          isPrivate
        />

        <Route
          path="/produto-editar/:id"
          component={ProductRegistration}
          isPrivate
        />

        <Route
          path="/recuperar-senha/:token?/:type?"
          component={ForgetPassword}
        />

        {/* <Route path="/recuperar-token" component={ForgetToken} /> */}
        <Route path="/recuperar-token/:token" component={ForgetToken} />

        <Route path="/loja" component={Store} isPrivate />
        <Route path="/agenda" component={Schedule} isPrivate />

        {/* Financeiro */}
        <Route
          path="/financeiro/relatorio/export"
          component={ExportSalesReport}
          isPrivate
        />
        <Route exact path="/financeiro" component={Financial} isPrivate />
        <Route path="/financeiro/transferencia" component={Transfer} isPrivate />
        <Route path="/financeiro/relatorio" component={SalesReport} isPrivate />
        <Route path="/financeiro/banco" component={Bankdetails} isPrivate />
        
        <Route
          path="/financeiro/extrato"
          component={FinancialExtract}
          isPrivate
        />
        
        <Route
          path="/financeiro/pedidos/:id"
          component={RequestsInfo}
          isPrivate
        />

        {/* Pedidos */}
        <Route exact path="/pedidos" component={Requests} isPrivate />
        <Route path="/pedidos/:id" component={RequestsInfo} isPrivate />
        
        <Route path="/notificacoes" component={NotificationsPage} isPrivate />

        <Route path="/suporte" component={SupportPage} isPrivate />
        <Route path="/promocional" component={SaleOff} isPrivate />
        <Route path="/redirect" component={RedirectPage} />
        <Route path="/mobile" component={MobileErrorPage} isPrivate />
        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/" component={() => <Redirect to="/login" />} />
      </Switch>
    </Suspense>
);

export default Routes;
