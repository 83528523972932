import React, { createContext, useCallback, useEffect, useState } from 'react';
import LogRocket from 'logrocket';
import { Freshchat } from 'reactjs-freshchat';
import { useHistory } from 'react-router-dom';
import api from '../services/api';
import 'reactjs-freshchat/dist/index.css';
import serviceWorker from '../serviceWorker';
import { NotificationProvider } from '../contexts/NotificationsContext';
import { getJWTPayload } from '../utils/jwt';
import { Firebase } from '../services/config/firebase';
import * as Sentry from "@sentry/browser";

interface AuthState {
  token: string;
  email: string;
  petshop: any;
}

interface SignInCredentials {
  emailLogin: string;
  password: string;
}

interface AuthContextData {
  data: AuthState;
  email: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  setData: (petshop: any) => void;
  signInFromWebsite(token: string): boolean;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

export const AuthProvider: React.FC = ({ children }) => {
  const { firebaseAuthentication, firebaseLogout } = Firebase;
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@iPetis:token');
    const email = localStorage.getItem('@iPetis:email');
    const petshop = localStorage.getItem('@iPetis:petshop');

    if (token && email && petshop) {
      
      return { token, email, petshop: JSON.parse(petshop) }
    }

    return {} as AuthState
  });

  const history = useHistory();

  function identifyUserToLog(name: string, email: string) {
    if (process.env.REACT_APP_ENV === 'production') {
      LogRocket.identify('dzkzto/dashboard-web', {
        name,
        email,
  
        // Add your own custom user variables here, ie:
        subscriptionType: 'pro',
      });
      Sentry.setUser({ email: "john.doe@example.com" });
    }
  }

  const signInFromWebsite = useCallback((token: string) => {
    if (token === 'dashboard') {
      return false;
    }

    const payload = getJWTPayload(token);

    const petshop = { ...payload.data.petshop };
    const { email } = petshop;
    localStorage.setItem('@iPetis:token', token);
    localStorage.setItem('@iPetis:email', email);
    localStorage.setItem('@iPetis:petshop', JSON.stringify(petshop));

    identifyUserToLog(petshop.trade_name, email) 

    setData({ token, email, petshop });
    firebaseAuthentication();
    return true;
  }, []);

  const signIn = useCallback(async ({ emailLogin, password }): Promise<any> => {
    const response = await api.post('session', {
      email: emailLogin,
      password,
    });

    const { token, email, role } = response.data;
    const petshop = { ...response.data.petshop.petshop };

    if (!(role === 'application_user')) {
      localStorage.setItem('@iPetis:token', token.token);
      localStorage.setItem('@iPetis:refresh-token', token.refreshToken);
      localStorage.setItem('@iPetis:email', email);
      localStorage.setItem('@iPetis:petshop', JSON.stringify(petshop));

      setData({ token, email, petshop });

      identifyUserToLog(petshop.trade_name, email) 
      
      try {
        await serviceWorker();
        
      } catch (error) {
        const err = error as any
        LogRocket.captureException(err)
      }
      
      firebaseAuthentication();
      return { isManager: true };
    }
    return { isManager: false };
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@iPetis:token');
    localStorage.removeItem('@iPetis:email');
    localStorage.removeItem('@iPetis:petshop');
    localStorage.removeItem('@iPetis:refresh-token');
    localStorage.clear();
    setData({} as AuthState);
    firebaseLogout();
    history.push('/login');
  }, []);

  const getInitialUserData = () => {
    if (!data) {
      const token = localStorage.getItem('@iPetis:token');
      const email = localStorage.getItem('@iPetis:email');
      const petshop = localStorage.getItem('@iPetis:petshop');
  
      if (token && email && petshop) {
        setData({ token, email, petshop: JSON.parse(petshop) })
        
        // identify user for the logrocket app
        identifyUserToLog(JSON.parse(petshop).trade_name, email) 
        
        history.push('/dashboard')
      }
    }
    
  }

  const getInitialData = useCallback(getInitialUserData, [])

  useEffect(() => {
    getInitialData()
  }, []) 

  return (
    <AuthContext.Provider
      value={{
        data,
        email: data.email,
        signIn,
        signOut,
        setData,
        signInFromWebsite,
      }}
    >
      {data.petshop ? (
        <NotificationProvider>
          {children}
          {/* <Freshchat
            token="03c69b1e-6a70-4e8f-9380-d56ba7a5df8f"
            externalId={data.email}
            firstName={data.petshop.trade_name}
            lastName={data.petshop.register_name}
            label="Suporte"
            ic_styles={{
              backgroundColor: '#381c4f',
              color: '#fff',
            }}
          /> */}
        </NotificationProvider>
      ) : (
        <>{children}</>
      )}
    </AuthContext.Provider>
  );
};