import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import environment from './Environment';
class HttpService {
  service: AxiosInstance;
  constructor() {
    const Environment: any = environment;
    const service = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },

      baseURL: Environment.baseUrl[Environment.currentEnvironment].urlApi,
    });

    service.interceptors.request.use((request) => {
      const idToken = window.localStorage.getItem('@iPetis:token');

      if (idToken) request.headers.Authorization = `Bearer ${idToken}`;

      return request;
    });

    service.interceptors.response.use(
      (response) => {
        return response
      }, 
      (error) => {
        if (error.response.status === 401) {
          localStorage.clear()
          // redirect to login page
          window.location.assign('/login?token_expired=true')
        }
        return Promise.reject(error);
      }
    )

    this.service = service;
  }

  get(path: string) {
    return this.service.get(path);
  }

  post<T>(path: string, body: T) {
    return this.service.post(path, body);
  }

  put<T>(path: string, body: T) {
    let conf: AxiosRequestConfig = {};

    conf.validateStatus = (status: number) => {
        
      return status < 400 
    }

    return this.service.put(path, body, conf);
  }

  delete(path: string, body: any) {
    return this.service.delete(path, body);
  }
}

export default HttpService;
