import axios from 'axios';

import environment from './config/Environment';

const Environment: any = environment;

const api = axios.create({
  baseURL: Environment.baseUrl[Environment.currentEnvironment].urlApi,
});

api.interceptors.request.use((request) => {
  const idToken = window.localStorage.getItem('@iPetis:token');

  if (idToken) request.headers.Authorization = `Bearer ${idToken}`;

  return request;
});

api.interceptors.response.use(
  (response) => {
    return response
  }, 
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear()
      // redirect to login page
      window.location.assign('/login?token_expired=true')
    }
  }
)

export default api;
